import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loading, Tabs } from 'components';
import { helpers } from 'helpers';

const ReportsLogs = React.lazy(() => import('./Logs'));
const ReportsMessages = React.lazy(() => import('./Messages'));

const Settings = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const items = [
        { key: 'logs', label: 'Activities Log', children: <ReportsLogs />, permission: '', },
        { key: 'messages', label: 'Messages', children: <ReportsMessages />, permission: '', },
    ].filter(item => !item.permission || helpers.hasPermission(item.permission));

    const current = location.pathname.split('/')[2] || items[0].key;

    return (
        <React.Fragment>
            <React.Suspense fallback={<Loading spinning={true} />}>
                <Tabs
                    type="card"
                    value={current}
                    items={items}
                    onChange={(e) => navigate(e)}
                />
            </React.Suspense>
        </React.Fragment>
    );
}

export default Settings;