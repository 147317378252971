import React from 'react';
import { helpers, types } from 'helpers';
import { Form, Select, Tabs } from 'antd';


const SettingsConfigProviders = ({ conf, currencies }: { conf: any; currencies: types.Currencies[] }) => {

    const conff = conf.providers_allocation || {};
    let tabs: string[] = currencies;
    if (window.location.pathname.includes('/settings')) {
        tabs = [...tabs, 'ACTIVE'];
    }

    return (
        <React.Fragment>
            <div className="p-4">
                <Tabs
                    defaultActiveKey={currencies[0]}
                    type="card"
                    tabPosition="left"
                    items={
                        tabs.map((currency) => {
                            const provsCard = (conff[currency]?.CARD || []).filter((p: string) => (conf.providers?.ACTIVE?.DEBIT || []).includes(p));
                            const provsDebit = (conff[currency]?.DEBIT || []).filter((p: string) => (conf.providers?.ACTIVE?.DEBIT || []).includes(p));
                            const provsCredit = (conff[currency]?.CREDIT || []).filter((p: string) => (conf.providers?.ACTIVE?.CREDIT || []).includes(p));
                            return {
                                key: currency,
                                label: <span>&nbsp;&nbsp;{currency}&nbsp;&nbsp;</span>,
                                children: (
                                    <div key={currency} className="grid grid-cols-2 gap-6">
                                        {['NGN'].includes(currency) && provsDebit.length > 0 && (
                                            <div className="p-4 bg-red-50 border rounded-lg">
                                                <Form.Item label={`${currency} collection (Bank)`} name={['providers', currency, types.TrxTypes.DEBIT, types.TrxChannels.BANK.toLowerCase()]} className="mb-2">
                                                    <Select size="large" mode="tags">
                                                        {provsDebit.map((option: string) => (
                                                            <Select.Option key={option} value={option}>
                                                                {helpers.ucFirst(option)}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        )}

                                        {['GHS', 'NGN'].includes(currency) && provsCredit.length > 0 && (
                                            <div className="p-4 bg-red-50 border rounded-lg">
                                                <Form.Item label={`${currency} payout (Bank)`} name={['providers', currency, types.TrxTypes.CREDIT, types.TrxChannels.BANK.toLowerCase()]} className="mb-2">
                                                    <Select size="large" mode="tags">
                                                        {provsCredit.map((option: string) => (
                                                            <Select.Option key={option} value={option}>
                                                                {helpers.ucFirst(option)}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        )}

                                        {['GHS', 'UGX', 'XAF', 'XOF', 'KES', 'TZS', 'ZMW'].includes(currency) && (
                                            <>
                                                {provsDebit.length > 0 && (
                                                    <div className="p-4 bg-red-50 border rounded-lg my-6s">
                                                        <Form.Item label={`${currency} collection (Momo)`} name={['providers', currency, types.TrxTypes.DEBIT, types.TrxChannels.MOMO.toLowerCase()]} className="mb-2">
                                                            <Select size="large" mode="tags">
                                                                {provsDebit.map((option: string) => (
                                                                    <Select.Option key={option} value={option}>
                                                                        {helpers.ucFirst(option)}
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                )}
                                                {provsCredit.length > 0 && (
                                                    <div className="p-4 bg-red-50 border rounded-lg">
                                                        <Form.Item label={`${currency} payout (Momo)`} name={['providers', currency, types.TrxTypes.CREDIT, types.TrxChannels.MOMO.toLowerCase()]} className="mb-2">
                                                            <Select size="large" mode="tags">
                                                                {provsCredit.map((option: string) => (
                                                                    <Select.Option key={option} value={option}>
                                                                        {helpers.ucFirst(option)}
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                )}
                                                {provsCard.length > 0 && (
                                                    <div className="p-4 bg-red-50 border rounded-lg my-6s">
                                                        <Form.Item label={`${currency} collection (Card)`} name={['providers', currency, types.TrxTypes.DEBIT, types.TrxChannels.CARD.toLowerCase()]} className="mb-2">
                                                            <Select size="large" mode="multiple">
                                                                {provsCard.map((option: string) => (
                                                                    <Select.Option key={option} value={option}>
                                                                        {helpers.ucFirst(option)}
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                )}
                                            </>
                                        )}

                                        {['ACTIVE'].includes(currency) && (
                                            <>
                                                <div className="p-4 bg-red-50 border rounded-lg">
                                                    <Form.Item label={`Active providers (Debit)`} name={['providers', currency, types.TrxTypes.DEBIT]} className="mb-2">
                                                        <Select size="large" mode="tags">
                                                            {Object.keys(types.TrxProviders).map((option: string) => (
                                                                <Select.Option key={option} value={option}>
                                                                    {helpers.ucFirst(option)}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                <div className="p-4 bg-red-50 border rounded-lg">
                                                    <Form.Item label={`Active providers (Credit)`} name={['providers', currency, types.TrxTypes.CREDIT]} className="mb-2">
                                                        <Select size="large" mode="tags">
                                                            {Object.keys(types.TrxProviders).map((option: string) => (
                                                                <Select.Option key={option} value={option}>
                                                                    {helpers.ucFirst(option)}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                ),
                            };
                        })
                    }
                />

                <div className="grids grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-6 hidden">
                    {currencies.map(currency => (
                        <div key={currency} />
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
}

export default SettingsConfigProviders;