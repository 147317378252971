import React from 'react';
import { axius, datatables, helpers, types } from 'helpers';
import { ExportData, Filter, Loading, Pagination, Table } from 'components';
import { useTypedSelector } from 'Store/_Store';
import dayjs from 'dayjs';
import { Tag } from 'antd';

const TransactionDetails = React.lazy(() => import('./Transactions/TransactionDetails'));


const Statement = () => {

    const { _utils: { limit } } = useTypedSelector((state) => state);

    const [row, setRow] = React.useState<types.Transaction>({} as types.Transaction);
    const [meta, setMeta] = React.useState({ next: '', previous: '', page: 1, total: 0, });
    const [data, setData] = React.useState<types.Transaction[]>([] as types.Transaction[]);
    const [loading, setLoading] = React.useState(false);
    const [filters, setFilters] = React.useState<types.Filter[]>([
        { key: 'date_range', type: 'date_range', label: 'Date range', value: `${dayjs().startOf('M').format('YYYY-MM-DD')},${dayjs().endOf('M').format('YYYY-MM-DD')}` },
        { key: 'id', type: 'text', label: 'Transaction ID / Reference', },
        { key: 'businessId', label: 'Merchants', type: 'select', options: [] },
        { key: 'provider', label: 'Provider', type: 'select', options: Object.keys(types.TrxProviders), },
        { key: 'currency', type: 'select', options: Object.keys(types.Currencies).map(value => { return { value, label: `(${value}) ${types.CurrenciesLabels[value as types.Currencies]}` } }), },
        { key: 'status', type: 'select', options: Object.keys(types.TrxStatus).map(value => { return { value, label: `${types.TrxStatusLabels[value as types.TrxStatus]}` } }), },
        { key: 'channel', type: 'select', options: Object.keys(types.TrxChannels).map(value => { return { value, label: `${types.TrxChannelsLabels[value as types.TrxChannels]}` } }), },
        { key: 'type', type: 'select', options: [types.TrxTypes.DEBIT, types.TrxTypes.CREDIT].map(value => { return { value, label: helpers.ucFirst(value) } }), },
        { key: 'apiKey', type: 'select', label: 'Stage', options: ['live', 'test'], value: 'live', },
    ]);

    const query = helpers.filterQuery(filters);

    React.useEffect(() => {
        axius.get(`business`, { status: types.TeamStatus.ACCEPTED, }).then(res => {
            // setBusiness(res.status === 200 ? res.data.data : []);
            const copyFilters = helpers.copyObject(filters) as types.Filter[];
            const k = copyFilters.indexOf(copyFilters.find(b => b.key === 'businessId') as types.Filter);
            if (k > -1) {
                copyFilters[k]['options'] = (res.data.data as types.Business[] || [])
                    .sort((a, b) => helpers.sortString(a, b, 'name'))
                    .map(b => { return { value: b.id, label: b.name } });
                setFilters(copyFilters);
            }
        });
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        query.date_from && query.date_to && getData();
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        getData();
        // eslint-disable-next-line
    }, [filters]);

    const getData = (cursor = `0-${limit}`) => {
        setLoading(true);
        axius.get('transactions', { cursor, ...query, }).then(res => {
            if (res.status === 200) {
                setMeta(res.data.meta);
                setData(res.data.data);
            }
            setLoading(false);
        });
    }

    return (
        <React.Fragment>
            <React.Suspense fallback={<Loading spinning={true} />}>
                <div className="bg-white p-4 rounded-xl border_ shadow-sm_">
                    <div className="grid grid-cols-12 gap-4 w-fulls">
                        <div className="col-span-12 xl:col-span-6">
                            <Filter filters={filters} setFilters={setFilters} />
                        </div>
                        <div className="col-span-12 xl:col-span-6 xl:text-right">
                            {helpers.hasPermission('STATEMENT_EXPORT') && (
                                <ExportData
                                    name={`Statement ${query.date_from}-${query.date_to}`}
                                    mapData={(e: types.Transaction[]) => datatables.Transactions.export(e)}
                                    fetchData={{
                                        query: { ...helpers.filterQuery(filters), },
                                        endpoint: 'transactions',
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>

                {query.date_from && query.date_to ? (
                    <div>
                        <div className="rounded-xl mt-8 bg-white border_ shadow-sm_">
                            <div className="font-semibold text-lg p-6">
                                Statement&nbsp;
                                <Tag color="green">{helpers.format.number(meta.total, 0)}</Tag>
                            </div>
                            <Table
                                loading={loading}
                                heading={['Business', 'Provider', ...datatables.Transactions.heading]}
                                data={datatables.Transactions.data(data).map(row => {
                                    const trs = data.find(d => d.id === row.id);
                                    return {
                                        business: trs?.business_name,
                                        provider: trs?.provider,
                                        ...row,
                                    };
                                })}
                                onClick={(e) => setRow(data.find(row => row.id === e.id) as types.Transaction)}
                            />
                        </div>
                        <div className="bg-white p-4 border_ shadow-sm_ mt-8 rounded-xl">
                            <Pagination meta={meta} navigate={getData} />
                        </div>
                    </div>
                ) : (
                    <div className="bg-white p-4 border_ shadow-sm_ mt-8 rounded-xl text-center">
                        Please apply the filters above
                    </div>
                )}

                <TransactionDetails
                    row={row}
                    open={!!(row.id)}
                    onClose={() => setRow({} as types.Transaction)}
                />
            </React.Suspense>
        </React.Fragment>
    );
}

export default Statement;