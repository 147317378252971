import React from 'react';
import { Button, Drawer, Form, notification } from 'antd';
import { axius, helpers, images, types } from 'helpers';
import { useEditBusinessMutation } from 'Store/api/auth.api';
import { useDispatch } from 'react-redux';
import { _setUtils } from 'Store/slices/utils.slice';


import SettingsConfigProviders from 'pages/Settings/Config.Providers';

interface Props {
    row?: types.Business;
    open: boolean;
    onClose: () => void;
    onSuccess?: (e: types.Business) => void;
}
const FormBusinessProviders = ({ open, row, onClose, onSuccess }: Props) => {

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const [conf, setConf] = React.useState<any>({});
    const [editBusiness, { isLoading, isError, isSuccess, error, data }] = useEditBusinessMutation();

    React.useEffect(() => {
        if (row?.id && open) {
            form.setFieldsValue({
                providers: row?.preferences?.providers || {},
            });
            axius.get(`configuration`, { cache: helpers.randNum(32) }).then(res => {
                res.status === 200 && setConf(res.data);
            });
        } else {
            form.resetFields();
        }
        // eslint-disable-next-line
    }, [row, open]);

    React.useEffect(() => {
        isError && notification.error({ message: (error as any).data?.message || ((error as any).data || []).join(', ') });
        if (isSuccess) {
            notification.success({ message: 'Business permissions saved' });
            dispatch(_setUtils({ key: 'meCache', value: data?.updated_at }));
            onSuccess && onSuccess(data as types.Business);
            onClose();
        }
        // eslint-disable-next-line
    }, [isError, isSuccess]);

    const submit = async (v: any) => {
        // console.log(v); return;
        if (row) {
            v['id'] = row.id;
            v['preferences'] = { ...row.preferences, providers: v.providers, };
            if (row?.id) {
                await editBusiness({ ...row, ...v, });
            }
        }
    }

    const currencies = Object.keys(types.Currencies).filter(c => row?.preferences?.permissions?.includes(c)).filter(c => !['GBP', 'EUR', 'USD', 'USDT', 'USDC'].includes(c)) as types.Currencies[];

    return (
        <React.Fragment>
            <Drawer
                open={open}
                title={null}
                width={880}
                closable={true}
                onClose={onClose}
                maskClosable={false}
            >
                <Form form={form} layout="vertical" onFinish={submit}>
                    <div className="flex justify-between items-center mb-8">
                        <div className="text-xl font-semibold">Edit business' providers</div>
                        <div
                            className="bg-gray-100 rounded-lg cursor-pointer hover:bg-gray-200 p-3 ml-2"
                            onClick={onClose}
                        >
                            <img src={images.Close} alt="Close" />
                        </div>
                    </div>
                    <div className="-p-4" style={{ padding: -4 }}>
                        <SettingsConfigProviders conf={conf} currencies={currencies} />
                    </div>
                    <div className="flex justify-end mt-8">
                        <Button type="primary" size="large" htmlType="submit" block loading={isLoading}>
                            Submit
                        </Button>
                    </div>
                </Form>
            </Drawer>
        </React.Fragment>
    );
};

export default FormBusinessProviders;