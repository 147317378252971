// All countries
// length 252
export const countries = [
    { name: "Afghanistan", code: "AF", id: 1, phone: 93, symbol: "؋", capital: "Kabul", currency: "AFN", continent: "Asia", continent_code: "AS", alpha_3: "AFG" },
    { name: "Aland Islands", code: "AX", id: 2, phone: 358, symbol: "€", capital: "Mariehamn", currency: "EUR", continent: "Europe", continent_code: "EU", alpha_3: "ALA" },
    { name: "Albania", code: "AL", id: 3, phone: 355, symbol: "Lek", capital: "Tirana", currency: "ALL", continent: "Europe", continent_code: "EU", alpha_3: "ALB" },
    { name: "Algeria", code: "DZ", id: 4, phone: 213, symbol: "دج", capital: "Algiers", currency: "DZD", continent: "Africa", continent_code: "AF", alpha_3: "DZA" },
    { name: "American Samoa", code: "AS", id: 5, phone: 1684, symbol: "$", capital: "Pago Pago", currency: "USD", continent: "Oceania", continent_code: "OC", alpha_3: "ASM" },
    { name: "Andorra", code: "AD", id: 6, phone: 376, symbol: "€", capital: "Andorra la Vella", currency: "EUR", continent: "Europe", continent_code: "EU", alpha_3: "AND" },
    { name: "Angola", code: "AO", id: 7, phone: 244, symbol: "Kz", capital: "Luanda", currency: "AOA", continent: "Africa", continent_code: "AF", alpha_3: "AGO" },
    { name: "Anguilla", code: "AI", id: 8, phone: 1264, symbol: "$", capital: "The Valley", currency: "XCD", continent: "North America", continent_code: "NA", alpha_3: "AIA" },
    { name: "Antarctica", code: "AQ", id: 9, phone: 672, symbol: "$", capital: "Antarctica", currency: "AAD", continent: "Antarctica", continent_code: "AN", alpha_3: "ATA" },
    { name: "Antigua and Barbuda", code: "AG", id: 10, phone: 1268, symbol: "$", capital: "St. John's", currency: "XCD", continent: "North America", continent_code: "NA", alpha_3: "ATG" },
    { name: "Argentina", code: "AR", id: 11, phone: 54, symbol: "$", capital: "Buenos Aires", currency: "ARS", continent: "South America", continent_code: "SA", alpha_3: "ARG" },
    { name: "Armenia", code: "AM", id: 12, phone: 374, symbol: "֏", capital: "Yerevan", currency: "AMD", continent: "Asia", continent_code: "AS", alpha_3: "ARM" },
    { name: "Aruba", code: "AW", id: 13, phone: 297, symbol: "ƒ", capital: "Oranjestad", currency: "AWG", continent: "North America", continent_code: "NA", alpha_3: "ABW" },
    { name: "Australia", code: "AU", id: 14, phone: 61, symbol: "$", capital: "Canberra", currency: "AUD", continent: "Oceania", continent_code: "OC", alpha_3: "AUS" },
    { name: "Austria", code: "AT", id: 15, phone: 43, symbol: "€", capital: "Vienna", currency: "EUR", continent: "Europe", continent_code: "EU", alpha_3: "AUT" },
    { name: "Azerbaijan", code: "AZ", id: 16, phone: 994, symbol: "m", capital: "Baku", currency: "AZN", continent: "Asia", continent_code: "AS", alpha_3: "AZE" },
    { name: "Bahamas", code: "BS", id: 17, phone: 1242, symbol: "B$", capital: "Nassau", currency: "BSD", continent: "North America", continent_code: "NA", alpha_3: "BHS" },
    { name: "Bahrain", code: "BH", id: 18, phone: 973, symbol: ".د.ب", capital: "Manama", currency: "BHD", continent: "Asia", continent_code: "AS", alpha_3: "BHR" },
    { name: "Bangladesh", code: "BD", id: 19, phone: 880, symbol: "৳", capital: "Dhaka", currency: "BDT", continent: "Asia", continent_code: "AS", alpha_3: "BGD" },
    { name: "Barbados", code: "BB", id: 20, phone: 1246, symbol: "Bds$", capital: "Bridgetown", currency: "BBD", continent: "North America", continent_code: "NA", alpha_3: "BRB" },
    { name: "Belarus", code: "BY", id: 21, phone: 375, symbol: "Br", capital: "Minsk", currency: "BYN", continent: "Europe", continent_code: "EU", alpha_3: "BLR" },
    { name: "Belgium", code: "BE", id: 22, phone: 32, symbol: "€", capital: "Brussels", currency: "EUR", continent: "Europe", continent_code: "EU", alpha_3: "BEL" },
    { name: "Belize", code: "BZ", id: 23, phone: 501, symbol: "$", capital: "Belmopan", currency: "BZD", continent: "North America", continent_code: "NA", alpha_3: "BLZ" },
    { name: "Benin", code: "BJ", id: 24, phone: 229, symbol: "CFA", capital: "Porto-Novo", currency: "XOF", continent: "Africa", continent_code: "AF", alpha_3: "BEN" },
    { name: "Bermuda", code: "BM", id: 25, phone: 1441, symbol: "$", capital: "Hamilton", currency: "BMD", continent: "North America", continent_code: "NA", alpha_3: "BMU" },
    { name: "Bhutan", code: "BT", id: 26, phone: 975, symbol: "Nu.", capital: "Thimphu", currency: "BTN", continent: "Asia", continent_code: "AS", alpha_3: "BTN" },
    { name: "Bolivia", code: "BO", id: 27, phone: 591, symbol: "Bs.", capital: "Sucre", currency: "BOB", continent: "South America", continent_code: "SA", alpha_3: "BOL" },
    { name: "Bonaire, Sint Eustatius and Saba", code: "BQ", id: 28, phone: 599, symbol: "$", capital: "Kralendijk", currency: "USD", continent: "North America", continent_code: "NA", alpha_3: "BES" },
    { name: "Bosnia and Herzegovina", code: "BA", id: 29, phone: 387, symbol: "KM", capital: "Sarajevo", currency: "BAM", continent: "Europe", continent_code: "EU", alpha_3: "BIH" },
    { name: "Botswana", code: "BW", id: 30, phone: 267, symbol: "P", capital: "Gaborone", currency: "BWP", continent: "Africa", continent_code: "AF", alpha_3: "BWA" },
    { name: "Bouvet Island", code: "BV", id: 31, phone: 55, symbol: "kr", capital: "", currency: "NOK", continent: "Antarctica", continent_code: "AN", alpha_3: "BVT" },
    { name: "Brazil", code: "BR", id: 32, phone: 55, symbol: "R$", capital: "Brasilia", currency: "BRL", continent: "South America", continent_code: "SA", alpha_3: "BRA" },
    { name: "British Indian Ocean Territory", code: "IO", id: 33, phone: 246, symbol: "$", capital: "Diego Garcia", currency: "USD", continent: "Asia", continent_code: "AS", alpha_3: "IOT" },
    { name: "Brunei Darussalam", code: "BN", id: 34, phone: 673, symbol: "B$", capital: "Bandar Seri Begawan", currency: "BND", continent: "Asia", continent_code: "AS", alpha_3: "BRN" },
    { name: "Bulgaria", code: "BG", id: 35, phone: 359, symbol: "Лв.", capital: "Sofia", currency: "BGN", continent: "Europe", continent_code: "EU", alpha_3: "BGR" },
    { name: "Burkina Faso", code: "BF", id: 36, phone: 226, symbol: "CFA", capital: "Ouagadougou", currency: "XOF", continent: "Africa", continent_code: "AF", alpha_3: "BFA" },
    { name: "Burundi", code: "BI", id: 37, phone: 257, symbol: "FBu", capital: "Bujumbura", currency: "BIF", continent: "Africa", continent_code: "AF", alpha_3: "BDI" },
    { name: "Cambodia", code: "KH", id: 38, phone: 855, symbol: "KHR", capital: "Phnom Penh", currency: "KHR", continent: "Asia", continent_code: "AS", alpha_3: "KHM" },
    { name: "Cameroon", code: "CM", id: 39, phone: 237, symbol: "FCFA", capital: "Yaounde", currency: "XAF", continent: "Africa", continent_code: "AF", alpha_3: "CMR" },
    { name: "Canada", code: "CA", id: 40, phone: 1, symbol: "$", capital: "Ottawa", currency: "CAD", continent: "North America", continent_code: "NA", alpha_3: "CAN" },
    { name: "Cape Verde", code: "CV", id: 41, phone: 238, symbol: "$", capital: "Praia", currency: "CVE", continent: "Africa", continent_code: "AF", alpha_3: "CPV" },
    { name: "Cayman Islands", code: "KY", id: 42, phone: 1345, symbol: "$", capital: "George Town", currency: "KYD", continent: "North America", continent_code: "NA", alpha_3: "CYM" },
    { name: "Central African Republic", code: "CF", id: 43, phone: 236, symbol: "FCFA", capital: "Bangui", currency: "XAF", continent: "Africa", continent_code: "AF", alpha_3: "CAF" },
    { name: "Chad", code: "TD", id: 44, phone: 235, symbol: "FCFA", capital: "N'Djamena", currency: "XAF", continent: "Africa", continent_code: "AF", alpha_3: "TCD" },
    { name: "Chile", code: "CL", id: 45, phone: 56, symbol: "$", capital: "Santiago", currency: "CLP", continent: "South America", continent_code: "SA", alpha_3: "CHL" },
    { name: "China", code: "CN", id: 46, phone: 86, symbol: "¥", capital: "Beijing", currency: "CNY", continent: "Asia", continent_code: "AS", alpha_3: "CHN" },
    { name: "Christmas Island", code: "CX", id: 47, phone: 61, symbol: "$", capital: "Flying Fish Cove", currency: "AUD", continent: "Asia", continent_code: "AS", alpha_3: "CXR" },
    { name: "Cocos (Keeling) Islands", code: "CC", id: 48, phone: 672, symbol: "$", capital: "West Island", currency: "AUD", continent: "Asia", continent_code: "AS", alpha_3: "CCK" },
    { name: "Colombia", code: "CO", id: 49, phone: 57, symbol: "$", capital: "Bogota", currency: "COP", continent: "South America", continent_code: "SA", alpha_3: "COL" },
    { name: "Comoros", code: "KM", id: 50, phone: 269, symbol: "CF", capital: "Moroni", currency: "KMF", continent: "Africa", continent_code: "AF", alpha_3: "COM" },
    { name: "Congo", code: "CG", id: 51, phone: 242, symbol: "FC", capital: "Brazzaville", currency: "XAF", continent: "Africa", continent_code: "AF", alpha_3: "COG" },
    { name: "Congo, Democratic Republic of the Congo", code: "CD", id: 52, phone: 242, symbol: "FC", capital: "Kinshasa", currency: "CDF", continent: "Africa", continent_code: "AF", alpha_3: "COD" },
    { name: "Cook Islands", code: "CK", id: 53, phone: 682, symbol: "$", capital: "Avarua", currency: "NZD", continent: "Oceania", continent_code: "OC", alpha_3: "COK" },
    { name: "Costa Rica", code: "CR", id: 54, phone: 506, symbol: "₡", capital: "San Jose", currency: "CRC", continent: "North America", continent_code: "NA", alpha_3: "CRI" },
    { name: "Cote D'Ivoire", code: "CI", id: 55, phone: 225, symbol: "CFA", capital: "Yamoussoukro", currency: "XOF", continent: "Africa", continent_code: "AF", alpha_3: "CIV" },
    { name: "Croatia", code: "HR", id: 56, phone: 385, symbol: "kn", capital: "Zagreb", currency: "HRK", continent: "Europe", continent_code: "EU", alpha_3: "HRV" },
    { name: "Cuba", code: "CU", id: 57, phone: 53, symbol: "$", capital: "Havana", currency: "CUP", continent: "North America", continent_code: "NA", alpha_3: "CUB" },
    { name: "Curacao", code: "CW", id: 58, phone: 599, symbol: "ƒ", capital: "Willemstad", currency: "ANG", continent: "North America", continent_code: "NA", alpha_3: "CUW" },
    { name: "Cyprus", code: "CY", id: 59, phone: 357, symbol: "€", capital: "Nicosia", currency: "EUR", continent: "Asia", continent_code: "AS", alpha_3: "CYP" },
    { name: "Czech Republic", code: "CZ", id: 60, phone: 420, symbol: "Kč", capital: "Prague", currency: "CZK", continent: "Europe", continent_code: "EU", alpha_3: "CZE" },
    { name: "Denmark", code: "DK", id: 61, phone: 45, symbol: "Kr.", capital: "Copenhagen", currency: "DKK", continent: "Europe", continent_code: "EU", alpha_3: "DNK" },
    { name: "Djibouti", code: "DJ", id: 62, phone: 253, symbol: "Fdj", capital: "Djibouti", currency: "DJF", continent: "Africa", continent_code: "AF", alpha_3: "DJI" },
    { name: "Dominica", code: "DM", id: 63, phone: 1767, symbol: "$", capital: "Roseau", currency: "XCD", continent: "North America", continent_code: "NA", alpha_3: "DMA" },
    { name: "Dominican Republic", code: "DO", id: 64, phone: 1809, symbol: "$", capital: "Santo Domingo", currency: "DOP", continent: "North America", continent_code: "NA", alpha_3: "DOM" },
    { name: "Ecuador", code: "EC", id: 65, phone: 593, symbol: "$", capital: "Quito", currency: "USD", continent: "South America", continent_code: "SA", alpha_3: "ECU" },
    { name: "Egypt", code: "EG", id: 66, phone: 20, symbol: "ج.م", capital: "Cairo", currency: "EGP", continent: "Africa", continent_code: "AF", alpha_3: "EGY" },
    { name: "El Salvador", code: "SV", id: 67, phone: 503, symbol: "$", capital: "San Salvador", currency: "USD", continent: "North America", continent_code: "NA", alpha_3: "SLV" },
    { name: "Equatorial Guinea", code: "GQ", id: 68, phone: 240, symbol: "FCFA", capital: "Malabo", currency: "XAF", continent: "Africa", continent_code: "AF", alpha_3: "GNQ" },
    { name: "Eritrea", code: "ER", id: 69, phone: 291, symbol: "Nfk", capital: "Asmara", currency: "ERN", continent: "Africa", continent_code: "AF", alpha_3: "ERI" },
    { name: "Estonia", code: "EE", id: 70, phone: 372, symbol: "€", capital: "Tallinn", currency: "EUR", continent: "Europe", continent_code: "EU", alpha_3: "EST" },
    { name: "Ethiopia", code: "ET", id: 71, phone: 251, symbol: "Nkf", capital: "Addis Ababa", currency: "ETB", continent: "Africa", continent_code: "AF", alpha_3: "ETH" },
    { name: "Falkland Islands (Malvinas)", code: "FK", id: 72, phone: 500, symbol: "£", capital: "Stanley", currency: "FKP", continent: "South America", continent_code: "SA", alpha_3: "FLK" },
    { name: "Faroe Islands", code: "FO", id: 73, phone: 298, symbol: "Kr.", capital: "Torshavn", currency: "DKK", continent: "Europe", continent_code: "EU", alpha_3: "FRO" },
    { name: "Fiji", code: "FJ", id: 74, phone: 679, symbol: "FJ$", capital: "Suva", currency: "FJD", continent: "Oceania", continent_code: "OC", alpha_3: "FJI" },
    { name: "Finland", code: "FI", id: 75, phone: 358, symbol: "€", capital: "Helsinki", currency: "EUR", continent: "Europe", continent_code: "EU", alpha_3: "FIN" },
    { name: "France", code: "FR", id: 76, phone: 33, symbol: "€", capital: "Paris", currency: "EUR", continent: "Europe", continent_code: "EU", alpha_3: "FRA" },
    { name: "French Guiana", code: "GF", id: 77, phone: 594, symbol: "€", capital: "Cayenne", currency: "EUR", continent: "South America", continent_code: "SA", alpha_3: "GUF" },
    { name: "French Polynesia", code: "PF", id: 78, phone: 689, symbol: "₣", capital: "Papeete", currency: "XPF", continent: "Oceania", continent_code: "OC", alpha_3: "PYF" },
    { name: "French Southern Territories", code: "TF", id: 79, phone: 262, symbol: "€", capital: "Port-aux-Francais", currency: "EUR", continent: "Antarctica", continent_code: "AN", alpha_3: "ATF" },
    { name: "Gabon", code: "GA", id: 80, phone: 241, symbol: "FCFA", capital: "Libreville", currency: "XAF", continent: "Africa", continent_code: "AF", alpha_3: "GAB" },
    { name: "Gambia", code: "GM", id: 81, phone: 220, symbol: "D", capital: "Banjul", currency: "GMD", continent: "Africa", continent_code: "AF", alpha_3: "GMB" },
    { name: "Georgia", code: "GE", id: 82, phone: 995, symbol: "ლ", capital: "Tbilisi", currency: "GEL", continent: "Asia", continent_code: "AS", alpha_3: "GEO" },
    { name: "Germany", code: "DE", id: 83, phone: 49, symbol: "€", capital: "Berlin", currency: "EUR", continent: "Europe", continent_code: "EU", alpha_3: "DEU" },
    { name: "Ghana", code: "GH", id: 84, phone: 233, symbol: "GH₵", capital: "Accra", currency: "GHS", continent: "Africa", continent_code: "AF", alpha_3: "GHA" },
    { name: "Gibraltar", code: "GI", id: 85, phone: 350, symbol: "£", capital: "Gibraltar", currency: "GIP", continent: "Europe", continent_code: "EU", alpha_3: "GIB" },
    { name: "Greece", code: "GR", id: 86, phone: 30, symbol: "€", capital: "Athens", currency: "EUR", continent: "Europe", continent_code: "EU", alpha_3: "GRC" },
    { name: "Greenland", code: "GL", id: 87, phone: 299, symbol: "Kr.", capital: "Nuuk", currency: "DKK", continent: "North America", continent_code: "NA", alpha_3: "GRL" },
    { name: "Grenada", code: "GD", id: 88, phone: 1473, symbol: "$", capital: "St. George's", currency: "XCD", continent: "North America", continent_code: "NA", alpha_3: "GRD" },
    { name: "Guadeloupe", code: "GP", id: 89, phone: 590, symbol: "€", capital: "Basse-Terre", currency: "EUR", continent: "North America", continent_code: "NA", alpha_3: "GLP" },
    { name: "Guam", code: "GU", id: 90, phone: 1671, symbol: "$", capital: "Hagatna", currency: "USD", continent: "Oceania", continent_code: "OC", alpha_3: "GUM" },
    { name: "Guatemala", code: "GT", id: 91, phone: 502, symbol: "Q", capital: "Guatemala City", currency: "GTQ", continent: "North America", continent_code: "NA", alpha_3: "GTM" },
    { name: "Guernsey", code: "GG", id: 92, phone: 44, symbol: "£", capital: "St Peter Port", currency: "GBP", continent: "Europe", continent_code: "EU", alpha_3: "GGY" },
    { name: "Guinea", code: "GN", id: 93, phone: 224, symbol: "FG", capital: "Conakry", currency: "GNF", continent: "Africa", continent_code: "AF", alpha_3: "GIN" },
    { name: "Guinea-Bissau", code: "GW", id: 94, phone: 245, symbol: "CFA", capital: "Bissau", currency: "XOF", continent: "Africa", continent_code: "AF", alpha_3: "GNB" },
    { name: "Guyana", code: "GY", id: 95, phone: 592, symbol: "$", capital: "Georgetown", currency: "GYD", continent: "South America", continent_code: "SA", alpha_3: "GUY" },
    { name: "Haiti", code: "HT", id: 96, phone: 509, symbol: "G", capital: "Port-au-Prince", currency: "HTG", continent: "North America", continent_code: "NA", alpha_3: "HTI" },
    { name: "Heard Island and McDonald Islands", code: "HM", id: 97, phone: 0, symbol: "$", capital: "", currency: "AUD", continent: "Antarctica", continent_code: "AN", alpha_3: "HMD" },
    { name: "Holy See (Vatican City State)", code: "VA", id: 98, phone: 39, symbol: "€", capital: "Vatican City", currency: "EUR", continent: "Europe", continent_code: "EU", alpha_3: "VAT" },
    { name: "Honduras", code: "HN", id: 99, phone: 504, symbol: "L", capital: "Tegucigalpa", currency: "HNL", continent: "North America", continent_code: "NA", alpha_3: "HND" },
    { name: "Hong Kong", code: "HK", id: 100, phone: 852, symbol: "$", capital: "Hong Kong", currency: "HKD", continent: "Asia", continent_code: "AS", alpha_3: "HKG" },
    { name: "Hungary", code: "HU", id: 101, phone: 36, symbol: "Ft", capital: "Budapest", currency: "HUF", continent: "Europe", continent_code: "EU", alpha_3: "HUN" },
    { name: "Iceland", code: "IS", id: 102, phone: 354, symbol: "kr", capital: "Reykjavik", currency: "ISK", continent: "Europe", continent_code: "EU", alpha_3: "ISL" },
    { name: "India", code: "IN", id: 103, phone: 91, symbol: "₹", capital: "New Delhi", currency: "INR", continent: "Asia", continent_code: "AS", alpha_3: "IND" },
    { name: "Indonesia", code: "ID", id: 104, phone: 62, symbol: "Rp", capital: "Jakarta", currency: "IDR", continent: "Asia", continent_code: "AS", alpha_3: "IDN" },
    { name: "Iran, Islamic Republic of", code: "IR", id: 105, phone: 98, symbol: "﷼", capital: "Tehran", currency: "IRR", continent: "Asia", continent_code: "AS", alpha_3: "IRN" },
    { name: "Iraq", code: "IQ", id: 106, phone: 964, symbol: "د.ع", capital: "Baghdad", currency: "IQD", continent: "Asia", continent_code: "AS", alpha_3: "IRQ" },
    { name: "Ireland", code: "IE", id: 107, phone: 353, symbol: "€", capital: "Dublin", currency: "EUR", continent: "Europe", continent_code: "EU", alpha_3: "IRL" },
    { name: "Isle of Man", code: "IM", id: 108, phone: 44, symbol: "£", capital: "Douglas, Isle of Man", currency: "GBP", continent: "Europe", continent_code: "EU", alpha_3: "IMN" },
    { name: "Israel", code: "IL", id: 109, phone: 972, symbol: "₪", capital: "Jerusalem", currency: "ILS", continent: "Asia", continent_code: "AS", alpha_3: "ISR" },
    { name: "Italy", code: "IT", id: 110, phone: 39, symbol: "€", capital: "Rome", currency: "EUR", continent: "Europe", continent_code: "EU", alpha_3: "ITA" },
    { name: "Jamaica", code: "JM", id: 111, phone: 1876, symbol: "J$", capital: "Kingston", currency: "JMD", continent: "North America", continent_code: "NA", alpha_3: "JAM" },
    { name: "Japan", code: "JP", id: 112, phone: 81, symbol: "¥", capital: "Tokyo", currency: "JPY", continent: "Asia", continent_code: "AS", alpha_3: "JPN" },
    { name: "Jersey", code: "JE", id: 113, phone: 44, symbol: "£", capital: "Saint Helier", currency: "GBP", continent: "Europe", continent_code: "EU", alpha_3: "JEY" },
    { name: "Jordan", code: "JO", id: 114, phone: 962, symbol: "ا.د", capital: "Amman", currency: "JOD", continent: "Asia", continent_code: "AS", alpha_3: "JOR" },
    { name: "Kazakhstan", code: "KZ", id: 115, phone: 7, symbol: "лв", capital: "Astana", currency: "KZT", continent: "Asia", continent_code: "AS", alpha_3: "KAZ" },
    { name: "Kenya", code: "KE", id: 116, phone: 254, symbol: "KSh", capital: "Nairobi", currency: "KES", continent: "Africa", continent_code: "AF", alpha_3: "KEN" },
    { name: "Kiribati", code: "KI", id: 117, phone: 686, symbol: "$", capital: "Tarawa", currency: "AUD", continent: "Oceania", continent_code: "OC", alpha_3: "KIR" },
    { name: "Korea, Democratic People's Republic of", code: "KP", id: 118, phone: 850, symbol: "₩", capital: "Pyongyang", currency: "KPW", continent: "Asia", continent_code: "AS", alpha_3: "PRK" },
    { name: "Korea, Republic of", code: "KR", id: 119, phone: 82, symbol: "₩", capital: "Seoul", currency: "KRW", continent: "Asia", continent_code: "AS", alpha_3: "KOR" },
    { name: "Kosovo", code: "XK", id: 120, phone: 381, symbol: "€", capital: "Pristina", currency: "EUR", continent: "Europe", continent_code: "EU", alpha_3: "XKX" },
    { name: "Kuwait", code: "KW", id: 121, phone: 965, symbol: "ك.د", capital: "Kuwait City", currency: "KWD", continent: "Asia", continent_code: "AS", alpha_3: "KWT" },
    { name: "Kyrgyzstan", code: "KG", id: 122, phone: 996, symbol: "лв", capital: "Bishkek", currency: "KGS", continent: "Asia", continent_code: "AS", alpha_3: "KGZ" },
    { name: "Lao People's Democratic Republic", code: "LA", id: 123, phone: 856, symbol: "₭", capital: "Vientiane", currency: "LAK", continent: "Asia", continent_code: "AS", alpha_3: "LAO" },
    { name: "Latvia", code: "LV", id: 124, phone: 371, symbol: "€", capital: "Riga", currency: "EUR", continent: "Europe", continent_code: "EU", alpha_3: "LVA" },
    { name: "Lebanon", code: "LB", id: 125, phone: 961, symbol: "£", capital: "Beirut", currency: "LBP", continent: "Asia", continent_code: "AS", alpha_3: "LBN" },
    { name: "Lesotho", code: "LS", id: 126, phone: 266, symbol: "L", capital: "Maseru", currency: "LSL", continent: "Africa", continent_code: "AF", alpha_3: "LSO" },
    { name: "Liberia", code: "LR", id: 127, phone: 231, symbol: "$", capital: "Monrovia", currency: "LRD", continent: "Africa", continent_code: "AF", alpha_3: "LBR" },
    { name: "Libyan Arab Jamahiriya", code: "LY", id: 128, phone: 218, symbol: "د.ل", capital: "Tripolis", currency: "LYD", continent: "Africa", continent_code: "AF", alpha_3: "LBY" },
    { name: "Liechtenstein", code: "LI", id: 129, phone: 423, symbol: "CHf", capital: "Vaduz", currency: "CHF", continent: "Europe", continent_code: "EU", alpha_3: "LIE" },
    { name: "Lithuania", code: "LT", id: 130, phone: 370, symbol: "€", capital: "Vilnius", currency: "EUR", continent: "Europe", continent_code: "EU", alpha_3: "LTU" },
    { name: "Luxembourg", code: "LU", id: 131, phone: 352, symbol: "€", capital: "Luxembourg", currency: "EUR", continent: "Europe", continent_code: "EU", alpha_3: "LUX" },
    { name: "Macao", code: "MO", id: 132, phone: 853, symbol: "$", capital: "Macao", currency: "MOP", continent: "Asia", continent_code: "AS", alpha_3: "MAC" },
    { name: "Macedonia, the Former Yugoslav Republic of", code: "MK", id: 133, phone: 389, symbol: "ден", capital: "Skopje", currency: "MKD", continent: "Europe", continent_code: "EU", alpha_3: "MKD" },
    { name: "Madagascar", code: "MG", id: 134, phone: 261, symbol: "Ar", capital: "Antananarivo", currency: "MGA", continent: "Africa", continent_code: "AF", alpha_3: "MDG" },
    { name: "Malawi", code: "MW", id: 135, phone: 265, symbol: "MK", capital: "Lilongwe", currency: "MWK", continent: "Africa", continent_code: "AF", alpha_3: "MWI" },
    { name: "Malaysia", code: "MY", id: 136, phone: 60, symbol: "RM", capital: "Kuala Lumpur", currency: "MYR", continent: "Asia", continent_code: "AS", alpha_3: "MYS" },
    { name: "Maldives", code: "MV", id: 137, phone: 960, symbol: "Rf", capital: "Male", currency: "MVR", continent: "Asia", continent_code: "AS", alpha_3: "MDV" },
    { name: "Mali", code: "ML", id: 138, phone: 223, symbol: "CFA", capital: "Bamako", currency: "XOF", continent: "Africa", continent_code: "AF", alpha_3: "MLI" },
    { name: "Malta", code: "MT", id: 139, phone: 356, symbol: "€", capital: "Valletta", currency: "EUR", continent: "Europe", continent_code: "EU", alpha_3: "MLT" },
    { name: "Marshall Islands", code: "MH", id: 140, phone: 692, symbol: "$", capital: "Majuro", currency: "USD", continent: "Oceania", continent_code: "OC", alpha_3: "MHL" },
    { name: "Martinique", code: "MQ", id: 141, phone: 596, symbol: "€", capital: "Fort-de-France", currency: "EUR", continent: "North America", continent_code: "NA", alpha_3: "MTQ" },
    { name: "Mauritania", code: "MR", id: 142, phone: 222, symbol: "MRU", capital: "Nouakchott", currency: "MRO", continent: "Africa", continent_code: "AF", alpha_3: "MRT" },
    { name: "Mauritius", code: "MU", id: 143, phone: 230, symbol: "₨", capital: "Port Louis", currency: "MUR", continent: "Africa", continent_code: "AF", alpha_3: "MUS" },
    { name: "Mayotte", code: "YT", id: 144, phone: 262, symbol: "€", capital: "Mamoudzou", currency: "EUR", continent: "Africa", continent_code: "AF", alpha_3: "MYT" },
    { name: "Mexico", code: "MX", id: 145, phone: 52, symbol: "$", capital: "Mexico City", currency: "MXN", continent: "North America", continent_code: "NA", alpha_3: "MEX" },
    { name: "Micronesia, Federated States of", code: "FM", id: 146, phone: 691, symbol: "$", capital: "Palikir", currency: "USD", continent: "Oceania", continent_code: "OC", alpha_3: "FSM" },
    { name: "Moldova, Republic of", code: "MD", id: 147, phone: 373, symbol: "L", capital: "Chisinau", currency: "MDL", continent: "Europe", continent_code: "EU", alpha_3: "MDA" },
    { name: "Monaco", code: "MC", id: 148, phone: 377, symbol: "€", capital: "Monaco", currency: "EUR", continent: "Europe", continent_code: "EU", alpha_3: "MCO" },
    { name: "Mongolia", code: "MN", id: 149, phone: 976, symbol: "₮", capital: "Ulan Bator", currency: "MNT", continent: "Asia", continent_code: "AS", alpha_3: "MNG" },
    { name: "Montenegro", code: "ME", id: 150, phone: 382, symbol: "€", capital: "Podgorica", currency: "EUR", continent: "Europe", continent_code: "EU", alpha_3: "MNE" },
    { name: "Montserrat", code: "MS", id: 151, phone: 1664, symbol: "$", capital: "Plymouth", currency: "XCD", continent: "North America", continent_code: "NA", alpha_3: "MSR" },
    { name: "Morocco", code: "MA", id: 152, phone: 212, symbol: "DH", capital: "Rabat", currency: "MAD", continent: "Africa", continent_code: "AF", alpha_3: "MAR" },
    { name: "Mozambique", code: "MZ", id: 153, phone: 258, symbol: "MT", capital: "Maputo", currency: "MZN", continent: "Africa", continent_code: "AF", alpha_3: "MOZ" },
    { name: "Myanmar", code: "MM", id: 154, phone: 95, symbol: "K", capital: "Nay Pyi Taw", currency: "MMK", continent: "Asia", continent_code: "AS", alpha_3: "MMR" },
    { name: "Namibia", code: "NA", id: 155, phone: 264, symbol: "$", capital: "Windhoek", currency: "NAD", continent: "Africa", continent_code: "AF", alpha_3: "NAM" },
    { name: "Nauru", code: "NR", id: 156, phone: 674, symbol: "$", capital: "Yaren", currency: "AUD", continent: "Oceania", continent_code: "OC", alpha_3: "NRU" },
    { name: "Nepal", code: "NP", id: 157, phone: 977, symbol: "₨", capital: "Kathmandu", currency: "NPR", continent: "Asia", continent_code: "AS", alpha_3: "NPL" },
    { name: "Netherlands", code: "NL", id: 158, phone: 31, symbol: "€", capital: "Amsterdam", currency: "EUR", continent: "Europe", continent_code: "EU", alpha_3: "NLD" },
    { name: "Netherlands Antilles", code: "AN", id: 159, phone: 599, symbol: "NAf", capital: "Willemstad", currency: "ANG", continent: "North America", continent_code: "NA", alpha_3: "ANT" },
    { name: "New Caledonia", code: "NC", id: 160, phone: 687, symbol: "₣", capital: "Noumea", currency: "XPF", continent: "Oceania", continent_code: "OC", alpha_3: "NCL" },
    { name: "New Zealand", code: "NZ", id: 161, phone: 64, symbol: "$", capital: "Wellington", currency: "NZD", continent: "Oceania", continent_code: "OC", alpha_3: "NZL" },
    { name: "Nicaragua", code: "NI", id: 162, phone: 505, symbol: "C$", capital: "Managua", currency: "NIO", continent: "North America", continent_code: "NA", alpha_3: "NIC" },
    { name: "Niger", code: "NE", id: 163, phone: 227, symbol: "CFA", capital: "Niamey", currency: "XOF", continent: "Africa", continent_code: "AF", alpha_3: "NER" },
    { name: "Nigeria", code: "NG", id: 164, phone: 234, symbol: "₦", capital: "Abuja", currency: "NGN", continent: "Africa", continent_code: "AF", alpha_3: "NGA" },
    { name: "Niue", code: "NU", id: 165, phone: 683, symbol: "$", capital: "Alofi", currency: "NZD", continent: "Oceania", continent_code: "OC", alpha_3: "NIU" },
    { name: "Norfolk Island", code: "NF", id: 166, phone: 672, symbol: "$", capital: "Kingston", currency: "AUD", continent: "Oceania", continent_code: "OC", alpha_3: "NFK" },
    { name: "Northern Mariana Islands", code: "MP", id: 167, phone: 1670, symbol: "$", capital: "Saipan", currency: "USD", continent: "Oceania", continent_code: "OC", alpha_3: "MNP" },
    { name: "Norway", code: "NO", id: 168, phone: 47, symbol: "kr", capital: "Oslo", currency: "NOK", continent: "Europe", continent_code: "EU", alpha_3: "NOR" },
    { name: "Oman", code: "OM", id: 169, phone: 968, symbol: ".ع.ر", capital: "Muscat", currency: "OMR", continent: "Asia", continent_code: "AS", alpha_3: "OMN" },
    { name: "Pakistan", code: "PK", id: 170, phone: 92, symbol: "₨", capital: "Islamabad", currency: "PKR", continent: "Asia", continent_code: "AS", alpha_3: "PAK" },
    { name: "Palau", code: "PW", id: 171, phone: 680, symbol: "$", capital: "Melekeok", currency: "USD", continent: "Oceania", continent_code: "OC", alpha_3: "PLW" },
    { name: "Palestinian Territory, Occupied", code: "PS", id: 172, phone: 970, symbol: "₪", capital: "East Jerusalem", currency: "ILS", continent: "Asia", continent_code: "AS", alpha_3: "PSE" },
    { name: "Panama", code: "PA", id: 173, phone: 507, symbol: "B/.", capital: "Panama City", currency: "PAB", continent: "North America", continent_code: "NA", alpha_3: "PAN" },
    { name: "Papua New Guinea", code: "PG", id: 174, phone: 675, symbol: "K", capital: "Port Moresby", currency: "PGK", continent: "Oceania", continent_code: "OC", alpha_3: "PNG" },
    { name: "Paraguay", code: "PY", id: 175, phone: 595, symbol: "₲", capital: "Asuncion", currency: "PYG", continent: "South America", continent_code: "SA", alpha_3: "PRY" },
    { name: "Peru", code: "PE", id: 176, phone: 51, symbol: "S/.", capital: "Lima", currency: "PEN", continent: "South America", continent_code: "SA", alpha_3: "PER" },
    { name: "Philippines", code: "PH", id: 177, phone: 63, symbol: "₱", capital: "Manila", currency: "PHP", continent: "Asia", continent_code: "AS", alpha_3: "PHL" },
    { name: "Pitcairn", code: "PN", id: 178, phone: 64, symbol: "$", capital: "Adamstown", currency: "NZD", continent: "Oceania", continent_code: "OC", alpha_3: "PCN" },
    { name: "Poland", code: "PL", id: 179, phone: 48, symbol: "zł", capital: "Warsaw", currency: "PLN", continent: "Europe", continent_code: "EU", alpha_3: "POL" },
    { name: "Portugal", code: "PT", id: 180, phone: 351, symbol: "€", capital: "Lisbon", currency: "EUR", continent: "Europe", continent_code: "EU", alpha_3: "PRT" },
    { name: "Puerto Rico", code: "PR", id: 181, phone: 1787, symbol: "$", capital: "San Juan", currency: "USD", continent: "North America", continent_code: "NA", alpha_3: "PRI" },
    { name: "Qatar", code: "QA", id: 182, phone: 974, symbol: "ق.ر", capital: "Doha", currency: "QAR", continent: "Asia", continent_code: "AS", alpha_3: "QAT" },
    { name: "Reunion", code: "RE", id: 183, phone: 262, symbol: "€", capital: "Saint-Denis", currency: "EUR", continent: "Africa", continent_code: "AF", alpha_3: "REU" },
    { name: "Romania", code: "RO", id: 184, phone: 40, symbol: "lei", capital: "Bucharest", currency: "RON", continent: "Europe", continent_code: "EU", alpha_3: "ROM" },
    { name: "Russian Federation", code: "RU", id: 185, phone: 70, symbol: "₽", capital: "Moscow", currency: "RUB", continent: "Asia", continent_code: "AS", alpha_3: "RUS" },
    { name: "Rwanda", code: "RW", id: 186, phone: 250, symbol: "FRw", capital: "Kigali", currency: "RWF", continent: "Africa", continent_code: "AF", alpha_3: "RWA" },
    { name: "Saint Barthelemy", code: "BL", id: 187, phone: 590, symbol: "€", capital: "Gustavia", currency: "EUR", continent: "North America", continent_code: "NA", alpha_3: "BLM" },
    { name: "Saint Helena", code: "SH", id: 188, phone: 290, symbol: "£", capital: "Jamestown", currency: "SHP", continent: "Africa", continent_code: "AF", alpha_3: "SHN" },
    { name: "Saint Kitts and Nevis", code: "KN", id: 189, phone: 1869, symbol: "$", capital: "Basseterre", currency: "XCD", continent: "North America", continent_code: "NA", alpha_3: "KNA" },
    { name: "Saint Lucia", code: "LC", id: 190, phone: 1758, symbol: "$", capital: "Castries", currency: "XCD", continent: "North America", continent_code: "NA", alpha_3: "LCA" },
    { name: "Saint Martin", code: "MF", id: 191, phone: 590, symbol: "€", capital: "Marigot", currency: "EUR", continent: "North America", continent_code: "NA", alpha_3: "MAF" },
    { name: "Saint Pierre and Miquelon", code: "PM", id: 192, phone: 508, symbol: "€", capital: "Saint-Pierre", currency: "EUR", continent: "North America", continent_code: "NA", alpha_3: "SPM" },
    { name: "Saint Vincent and the Grenadines", code: "VC", id: 193, phone: 1784, symbol: "$", capital: "Kingstown", currency: "XCD", continent: "North America", continent_code: "NA", alpha_3: "VCT" },
    { name: "Samoa", code: "WS", id: 194, phone: 684, symbol: "SAT", capital: "Apia", currency: "WST", continent: "Oceania", continent_code: "OC", alpha_3: "WSM" },
    { name: "San Marino", code: "SM", id: 195, phone: 378, symbol: "€", capital: "San Marino", currency: "EUR", continent: "Europe", continent_code: "EU", alpha_3: "SMR" },
    { name: "Sao Tome and Principe", code: "ST", id: 196, phone: 239, symbol: "Db", capital: "Sao Tome", currency: "STD", continent: "Africa", continent_code: "AF", alpha_3: "STP" },
    { name: "Saudi Arabia", code: "SA", id: 197, phone: 966, symbol: "﷼", capital: "Riyadh", currency: "SAR", continent: "Asia", continent_code: "AS", alpha_3: "SAU" },
    { name: "Senegal", code: "SN", id: 198, phone: 221, symbol: "CFA", capital: "Dakar", currency: "XOF", continent: "Africa", continent_code: "AF", alpha_3: "SEN" },
    { name: "Serbia", code: "RS", id: 199, phone: 381, symbol: "din", capital: "Belgrade", currency: "RSD", continent: "Europe", continent_code: "EU", alpha_3: "SRB" },
    { name: "Serbia and Montenegro", code: "CS", id: 200, phone: 381, symbol: "din", capital: "Belgrade", currency: "RSD", continent: "Europe", continent_code: "EU", alpha_3: "SCG" },
    { name: "Seychelles", code: "SC", id: 201, phone: 248, symbol: "SRe", capital: "Victoria", currency: "SCR", continent: "Africa", continent_code: "AF", alpha_3: "SYC" },
    { name: "Sierra Leone", code: "SL", id: 202, phone: 232, symbol: "Le", capital: "Freetown", currency: "SLL", continent: "Africa", continent_code: "AF", alpha_3: "SLE" },
    { name: "Singapore", code: "SG", id: 203, phone: 65, symbol: "$", capital: "Singapur", currency: "SGD", continent: "Asia", continent_code: "AS", alpha_3: "SGP" },
    { name: "St Martin", code: "SX", id: 204, phone: 1, symbol: "ƒ", capital: "Philipsburg", currency: "ANG", continent: "North America", continent_code: "NA", alpha_3: "SXM" },
    { name: "Slovakia", code: "SK", id: 205, phone: 421, symbol: "€", capital: "Bratislava", currency: "EUR", continent: "Europe", continent_code: "EU", alpha_3: "SVK" },
    { name: "Slovenia", code: "SI", id: 206, phone: 386, symbol: "€", capital: "Ljubljana", currency: "EUR", continent: "Europe", continent_code: "EU", alpha_3: "SVN" },
    { name: "Solomon Islands", code: "SB", id: 207, phone: 677, symbol: "Si$", capital: "Honiara", currency: "SBD", continent: "Oceania", continent_code: "OC", alpha_3: "SLB" },
    { name: "Somalia", code: "SO", id: 208, phone: 252, symbol: "Sh.so.", capital: "Mogadishu", currency: "SOS", continent: "Africa", continent_code: "AF", alpha_3: "SOM" },
    { name: "South Africa", code: "ZA", id: 209, phone: 27, symbol: "R", capital: "Pretoria", currency: "ZAR", continent: "Africa", continent_code: "AF", alpha_3: "ZAF" },
    { name: "South Georgia and the South Sandwich Islands", code: "GS", id: 210, phone: 500, symbol: "£", capital: "Grytviken", currency: "GBP", continent: "Antarctica", continent_code: "AN", alpha_3: "SGS" },
    { name: "South Sudan", code: "SS", id: 211, phone: 211, symbol: "£", capital: "Juba", currency: "SSP", continent: "Africa", continent_code: "AF", alpha_3: "SSD" },
    { name: "Spain", code: "ES", id: 212, phone: 34, symbol: "€", capital: "Madrid", currency: "EUR", continent: "Europe", continent_code: "EU", alpha_3: "ESP" },
    { name: "Sri Lanka", code: "LK", id: 213, phone: 94, symbol: "Rs", capital: "Colombo", currency: "LKR", continent: "Asia", continent_code: "AS", alpha_3: "LKA" },
    { name: "Sudan", code: "SD", id: 214, phone: 249, symbol: ".س.ج", capital: "Khartoum", currency: "SDG", continent: "Africa", continent_code: "AF", alpha_3: "SDN" },
    { name: "Suriname", code: "SR", id: 215, phone: 597, symbol: "$", capital: "Paramaribo", currency: "SRD", continent: "South America", continent_code: "SA", alpha_3: "SUR" },
    { name: "Svalbard and Jan Mayen", code: "SJ", id: 216, phone: 47, symbol: "kr", capital: "Longyearbyen", currency: "NOK", continent: "Europe", continent_code: "EU", alpha_3: "SJM" },
    { name: "Swaziland", code: "SZ", id: 217, phone: 268, symbol: "E", capital: "Mbabane", currency: "SZL", continent: "Africa", continent_code: "AF", alpha_3: "SWZ" },
    { name: "Sweden", code: "SE", id: 218, phone: 46, symbol: "kr", capital: "Stockholm", currency: "SEK", continent: "Europe", continent_code: "EU", alpha_3: "SWE" },
    { name: "Switzerland", code: "CH", id: 219, phone: 41, symbol: "CHf", capital: "Berne", currency: "CHF", continent: "Europe", continent_code: "EU", alpha_3: "CHE" },
    { name: "Syrian Arab Republic", code: "SY", id: 220, phone: 963, symbol: "LS", capital: "Damascus", currency: "SYP", continent: "Asia", continent_code: "AS", alpha_3: "SYR" },
    { name: "Taiwan, Province of China", code: "TW", id: 221, phone: 886, symbol: "$", capital: "Taipei", currency: "TWD", continent: "Asia", continent_code: "AS", alpha_3: "TWN" },
    { name: "Tajikistan", code: "TJ", id: 222, phone: 992, symbol: "SM", capital: "Dushanbe", currency: "TJS", continent: "Asia", continent_code: "AS", alpha_3: "TJK" },
    { name: "Tanzania, United Republic of", code: "TZ", id: 223, phone: 255, symbol: "TSh", capital: "Dodoma", currency: "TZS", continent: "Africa", continent_code: "AF", alpha_3: "TZA" },
    { name: "Thailand", code: "TH", id: 224, phone: 66, symbol: "฿", capital: "Bangkok", currency: "THB", continent: "Asia", continent_code: "AS", alpha_3: "THA" },
    { name: "Timor-Leste", code: "TL", id: 225, phone: 670, symbol: "$", capital: "Dili", currency: "USD", continent: "Asia", continent_code: "AS", alpha_3: "TLS" },
    { name: "Togo", code: "TG", id: 226, phone: 228, symbol: "CFA", capital: "Lome", currency: "XOF", continent: "Africa", continent_code: "AF", alpha_3: "TGO" },
    { name: "Tokelau", code: "TK", id: 227, phone: 690, symbol: "$", capital: "", currency: "NZD", continent: "Oceania", continent_code: "OC", alpha_3: "TKL" },
    { name: "Tonga", code: "TO", id: 228, phone: 676, symbol: "$", capital: "Nuku'alofa", currency: "TOP", continent: "Oceania", continent_code: "OC", alpha_3: "TON" },
    { name: "Trinidad and Tobago", code: "TT", id: 229, phone: 1868, symbol: "$", capital: "Port of Spain", currency: "TTD", continent: "North America", continent_code: "NA", alpha_3: "TTO" },
    { name: "Tunisia", code: "TN", id: 230, phone: 216, symbol: "ت.د", capital: "Tunis", currency: "TND", continent: "Africa", continent_code: "AF", alpha_3: "TUN" },
    { name: "Turkey", code: "TR", id: 231, phone: 90, symbol: "₺", capital: "Ankara", currency: "TRY", continent: "Asia", continent_code: "AS", alpha_3: "TUR" },
    { name: "Turkmenistan", code: "TM", id: 232, phone: 7370, symbol: "T", capital: "Ashgabat", currency: "TMT", continent: "Asia", continent_code: "AS", alpha_3: "TKM" },
    { name: "Turks and Caicos Islands", code: "TC", id: 233, phone: 1649, symbol: "$", capital: "Cockburn Town", currency: "USD", continent: "North America", continent_code: "NA", alpha_3: "TCA" },
    { name: "Tuvalu", code: "TV", id: 234, phone: 688, symbol: "$", capital: "Funafuti", currency: "AUD", continent: "Oceania", continent_code: "OC", alpha_3: "TUV" },
    { name: "Uganda", code: "UG", id: 235, phone: 256, symbol: "USh", capital: "Kampala", currency: "UGX", continent: "Africa", continent_code: "AF", alpha_3: "UGA" },
    { name: "Ukraine", code: "UA", id: 236, phone: 380, symbol: "₴", capital: "Kiev", currency: "UAH", continent: "Europe", continent_code: "EU", alpha_3: "UKR" },
    { name: "United Arab Emirates", code: "AE", id: 237, phone: 971, symbol: "إ.د", capital: "Abu Dhabi", currency: "AED", continent: "Asia", continent_code: "AS", alpha_3: "ARE" },
    { name: "United Kingdom", code: "GB", id: 238, phone: 44, symbol: "£", capital: "London", currency: "GBP", continent: "Europe", continent_code: "EU", alpha_3: "GBR" },
    { name: "United States", code: "US", id: 239, phone: 1, symbol: "$", capital: "Washington", currency: "USD", continent: "North America", continent_code: "NA", alpha_3: "USA" },
    { name: "United States Minor Outlying Islands", code: "UM", id: 240, phone: 1, symbol: "$", capital: "", currency: "USD", continent: "North America", continent_code: "NA", alpha_3: "UMI" },
    { name: "Uruguay", code: "UY", id: 241, phone: 598, symbol: "$", capital: "Montevideo", currency: "UYU", continent: "South America", continent_code: "SA", alpha_3: "URY" },
    { name: "Uzbekistan", code: "UZ", id: 242, phone: 998, symbol: "лв", capital: "Tashkent", currency: "UZS", continent: "Asia", continent_code: "AS", alpha_3: "UZB" },
    { name: "Vanuatu", code: "VU", id: 243, phone: 678, symbol: "VT", capital: "Port Vila", currency: "VUV", continent: "Oceania", continent_code: "OC", alpha_3: "VUT" },
    { name: "Venezuela", code: "VE", id: 244, phone: 58, symbol: "Bs", capital: "Caracas", currency: "VEF", continent: "South America", continent_code: "SA", alpha_3: "VEN" },
    { name: "Viet Nam", code: "VN", id: 245, phone: 84, symbol: "₫", capital: "Hanoi", currency: "VND", continent: "Asia", continent_code: "AS", alpha_3: "VNM" },
    { name: "Virgin Islands, British", code: "VG", id: 246, phone: 1284, symbol: "$", capital: "Road Town", currency: "USD", continent: "North America", continent_code: "NA", alpha_3: "VGB" },
    { name: "Virgin Islands, U.s.", code: "VI", id: 247, phone: 1340, symbol: "$", capital: "Charlotte Amalie", currency: "USD", continent: "North America", continent_code: "NA", alpha_3: "VIR" },
    { name: "Wallis and Futuna", code: "WF", id: 248, phone: 681, symbol: "₣", capital: "Mata Utu", currency: "XPF", continent: "Oceania", continent_code: "OC", alpha_3: "WLF" },
    { name: "Western Sahara", code: "EH", id: 249, phone: 212, symbol: "MAD", capital: "El-Aaiun", currency: "MAD", continent: "Africa", continent_code: "AF", alpha_3: "ESH" },
    { name: "Yemen", code: "YE", id: 250, phone: 967, symbol: "﷼", capital: "Sanaa", currency: "YER", continent: "Asia", continent_code: "AS", alpha_3: "YEM" },
    { name: "Zambia", code: "ZM", id: 251, phone: 260, symbol: "ZK", capital: "Lusaka", currency: "ZMW", continent: "Africa", continent_code: "AF", alpha_3: "ZMB" },
    { name: "Zimbabwe", code: "ZW", id: 252, phone: 263, symbol: "$", capital: "Harare", currency: "ZWL", continent: "Africa", continent_code: "AF", alpha_3: "ZWE" }
];